<template>
  <div class="m-text-banner">
    <div class="container">
      <div class="m-text-banner__content">
        <div class="row">
          <div
            v-for="(item, i) in content.items"
            :key="i"
            class="col-lg-4"
          >
            <div class="m-text-banner__item">
              <base-image
                v-if="item.image && item.image[0]"
                :url="item.image[0].Url"
                :width="50"
                :height="50"
                mode="pad"
                :ratio="50/50"
                :alt-text="item.imageAltText"
                image-class="m-text-banner__image"
                further-options="&bgcolor=ffffff"
              />
              <h2 v-if="item.headline" class="m-text-banner__headline">
                {{ item.headline }}
              </h2>
              <div
                class="m-text-banner__text"
                v-html="item.text"
              >
                <!-- text -->
              </div>
              <base-link
                v-if="item.link"
                :link="item.link.url"
                class="m-text-banner__link"
                :target="item.link.target"
              >
                {{ item.link.name }}
                <base-icon icon="chevron-right" />
              </base-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import contentMixin from '@/mixins/contentMixin';
import BaseLink from '@/components/atoms/BaseLink';
import BaseImage from '@/components/atoms/BaseImage';
import BaseIcon from '@/components/atoms/BaseIcon';

export default {
  name: 'TextBanner',
  components: {
    BaseLink,
    BaseImage,
    BaseIcon
  },
  mixins: [contentMixin],
};
</script>
